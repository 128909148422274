import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React , { useEffect, useRef, useState } from 'react'
import { components, DataFlattener, useServices, useTranslation } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import NBCICanadaInvoiceApiUrls from 'src/apiUrls/NBCICanadaInvoiceApiUrls'
import PrintFlagDialog from 'src/views/nbcicanadainvoice/PrintFlagDialog'
import withFormState from '../../components/aciacehighway/hocs/withFormState'
import { useFormContext } from 'react-hook-form'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage(props) {
  const {
    history,
    id,
    invoiceData,
    onContinueNextStep,
    onDiscard,
    onSetInvoiceData,
    onSetLoading,
    showNotification
  } = props

  const [printFlagDialog, setPrintFlagDialog] = useState({ open: false, manifest: null })
  const onSuccessCallback = useRef(() => {})

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CngEditForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
              <FormProperties.Fields
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
                onSetLoading={onSetLoading}
              />
            )}
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            fetch={{
              url: NBCICanadaInvoiceApiUrls.GET,
              onPreSuccess: (serverData) => {
                onSetInvoiceData(serverData)
              }
            }}
            update={{
              url: NBCICanadaInvoiceApiUrls.PUT,
              onPostSubmitSuccess: (e) => {
                onSetInvoiceData(e)

                onSuccessCallback.current()
              }
            }}
            id={id}
            renderButtonSection={(_, loading) => (
              <EditPageButtonSection
                history={history}
                loading={loading}
                invoiceData={invoiceData}
                onDiscard={onDiscard}
                onPrint={() => setPrintFlagDialog({ open: true, manifest: invoiceData })}
                onSetLoading={onSetLoading}
                onSaveAndContinue={() => {
                  onSuccessCallback.current = onContinueNextStep
                }}
                onSkip={onContinueNextStep}
                showNotification={showNotification}
              />
            )}
          />
        </Grid>
      </Grid>
      <PrintFlagDialog
        openDialog={printFlagDialog}
        onClose={() => setPrintFlagDialog({ open: false, manifest: null })}
        showNotification={showNotification}
      />
    </>
  )
}

function EditPageButtonSection(props) {
  const {
    history,
    loading,
    invoiceData,
    onDiscard,
    onPrint,
    onSetLoading,
    onSaveAndContinue,
    onSkip,
    showNotification
  } = props
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const {
    formState: { isSubmitting }
  } = useFormContext()

  useEffect(() => {
    onSetLoading(isSubmitting)
  }, [isSubmitting])

  function makeTranslatedTextsObject() {
    const submitSuccessMsg = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.SUBMIT_SUCCESS_MSG
    )

    return { submitSuccessMsg }
  }

  function handleSubmit() {
    onSetLoading(true)

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      invoiceData.fileForUserId = fileForUserDetails.fileForUserId;
      invoiceData.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      invoiceData.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      NBCICanadaInvoiceApiUrls.SUBMIT,
      DataFlattener.unflatten(invoiceData),
      (response) => {
        var data = response.data
        var errorMessages = data.errorMessages
        if (response.status == 200 && data != null && errorMessages == null) {
          showNotification('success', translatedTextsObject.submitSuccessMsg)
          history.push(pathMap.N_B_C_I_CANADA_INVOICE_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      },
      (error) => console.error(error),
      () => onSetLoading(false)
    )
  }

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting}
            onClick={onDiscard}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                disabled={loading || isSubmitting}
                onClick={onSkip}
                size='medium'
              >
                Skip
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                onClick={handleSubmit}
                size='medium'
              >
                Submit
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                onClick={onPrint}
                size='medium'
              >
                Print
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                type='submit'
                size='medium'
              >
                Save as draft
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                type='submit'
                onClick={onSaveAndContinue}
                size='medium'
              >
                Save &amp; continue
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withFormState(EditPage)
