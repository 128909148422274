import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!`^_:;.?()[]{}\'"'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    transactionNo: validateStringField(
      50,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    containerNo1: validateStringField(
      14,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    containerNo2: validateStringField(
      14,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    containerNo3: validateStringField(
      14,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    flightNo: validateStringField(50, regexAlphaNumeric, errMsgAlphaNumeric),
    trailerNo: validateStringField(50, regexAlphaNumeric, errMsgAlphaNumeric),
    isDairy: Yup.string().nullable(),
    isProcessFoodVeg: Yup.string().nullable(),
    isHoney: Yup.string().nullable(),
    isMapleProd: Yup.string().nullable(),
    isSeeds: Yup.string().nullable(),
    isFeed: Yup.string().nullable(),
    isFortizer: Yup.string().nullable()
  })
}

export default makeValidationSchema
