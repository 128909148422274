import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!`^_:;.?()[]{}\'"'
  const regexDigitWithDecimal = '^[0-9]*.[0-9]*$'
  const errMsgDigitWithDecimal =
    'Invalid Value - Allowed Digits with/without decimal'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    typeNetContent: validateStringField(
      20,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    quantity: validateStringField(
      20,
      regexDigitWithDecimal,
      errMsgDigitWithDecimal
    ),
    labelNo: validateStringField(15, regexAlphaNumeric, errMsgAlphaNumeric),
    importPurpose: validateStringField(
      15,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    documentation: validateStringField(
      15,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    registrationNo: validateStringField(
      15,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    )
  })
}

export default makeValidationSchema
