import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!`^_:;.?()[]{}\'"'
  const regexDigitWithDecimal = '^[0-9]*.[0-9]*$'
  const errMsgDigitWithDecimal =
    'Invalid Value - Allowed Digits with/without decimal'
  const regexDigit = '^[0-9]*$'
  const errMsgDigit = 'Invalid Value - Allowed Digits'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    noOfPkgs: validateStringField(10, regexDigit, errMsgDigit),
    pkgType: Yup.string().nullable(),
    pkgDimLength: validateStringField(
      7,
      regexDigitWithDecimal,
      errMsgDigitWithDecimal
    ),
    pkgDimWidth: validateStringField(
      7,
      regexDigitWithDecimal,
      errMsgDigitWithDecimal
    ),
    pkgDimHeight: validateStringField(
      7,
      regexDigitWithDecimal,
      errMsgDigitWithDecimal
    ),
    pkgDimUom: Yup.string().nullable(),
    commodityDescription: validateStringField(
      30,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    grossWeight: validateStringField(
      9,
      regexDigitWithDecimal,
      errMsgDigitWithDecimal
    ),
    grossWeightUom: Yup.string().nullable(),
    freightClass: Yup.string().nullable()
  })
}

export default makeValidationSchema
