import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import moment from 'moment'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    productId: Yup.string()
      .trim()
      .min(1, 'Min length needed is 1.')
      .max(50, 'Max length allowed is 50.')
      .required(requiredMessage)
      .matches(
        '[a-zA-Z0-9\\-\\_]$',
        'Only alphanumeric values along with - and _ is allowed.'
      )
      .nullable(),
    manufacturingPartNo: Yup.string()
      .trim()
      .min(1, 'Min length needed is 1.')
      .max(25, 'Max length allowed is 25.')
      .required(requiredMessage)
      .test(
        'Check Product / Part Number',
        'Only aplhanumericvalues other than [,],{,},&,@,*,=,+,|,$,;,:,!. is allowed.',
        function () {
          let manufacturingPartNo = this.parent['manufacturingPartNo']
          var format = /[{}&@*=+\\|$;:!\[\]]/
          if (manufacturingPartNo) {
            if (!format.test(manufacturingPartNo)) {
              return true
            }
          }
        }
      )
      .nullable(),
    itemDescription: Yup.string()
      .trim()
      .min(1, 'Min length needed is 1.')
      .max(80, 'Max length allowed is 80.')
      .required(requiredMessage)
      .test(
        'Check Product Description',
        'Only alphanumeric values other than <,>,*,~,[,] is allowed.',
        function () {
          let itemDescription = this.parent['itemDescription']
          var format = /[<>*~\[\]]/
          if (itemDescription) {
            if (!format.test(itemDescription)) {
              return true
            }
          }
        }
      )
      .nullable(),
    productClassificationCode: Yup.string()
      .trim()
      .min(1, 'Min length needed is 1.')
      .max(8, 'Max length allowed is 8.')
      .matches(
        '^\\d{2}[a-zA-Z]{4}\\d{1}$|^\\d{2}[a-zA-Z]{3}\\d{1}[a-zA-Z]{1}$|^\\d{2}[a-zA-Z]{3}\\d{2}$|^\\d{2}[a-zA-Z]{5}$|^\\d{2}[a-zA-Z]{1}\\-{2}[a-zA-Z]{1}\\d{1}$|^\\d{2}[a-zA-Z]{1}\\-{2}\\d{1}[a-zA-Z]{1}$|^\\d{2}[a-zA-Z]{1}\\-{2}[a-zA-Z]{2}$|^\\d{2}[a-zA-Z]{1}\\-{2}\\d{2}$|^\\d{2}[a-zA-Z]{1}\\-{1}[a-zA-Z]{2}\\d{1}$|^\\d{2}[a-zA-Z]{1}\\-{1}[a-zA-Z]{1}\\d{1}[a-zA-Z]{1}$|^\\d{2}[a-zA-Z]{1}\\-{1}[a-zA-Z]{1}\\d{2}$|^\\d{2}[a-zA-Z]{1}\\-{1}[a-zA-Z]{3}$|^\\d{2}[a-zA-Z]{2}\\-{1}[a-zA-Z]{1}\\d{1}$|^\\d{2}[a-zA-Z]{2}\\-{1}\\d{1}[a-zA-Z]{1}$|^\\d{2}[a-zA-Z]{2}\\-{1}\\d{2}$|^\\d{2}[a-zA-Z]{2}\\-{1}[a-zA-Z]{2}$',
        'FDA Product Code is in invalid format.'
      ),
    countryOfOrigin: Yup.string().trim().required(requiredMessage).nullable(),
    uom: Yup.string()
      .trim()
      .required(requiredMessage)
      .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric values are allowed.')
      .nullable(),
    unitPrice: Yup.string()
      .trim()
      .max(19, 'Max length allowed is 19.')
      .matches(
        /^\d{0,7}(\.\d{0,7})?$/,
        'Only numeric values with up to 7 digits and 7 decimal points.'
      )
      .test(
        'Unit Price',
        'Unit Price value has to greater than 0.00.',
        function () {
          let unitPrice = this.parent['unitPrice']
          var format = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/
          if (unitPrice !== undefined) {
            if (format.test(unitPrice)) {
              return true
            }
          } else {
            return true
          }
        }
      ),
    tariffNo: Yup.string()
      .trim()
      .max(10, 'Max length allowed is 10.')
      .matches(
        /^\d+$/,
        'H.T.S. Tariff Number accepts only maximum of 10 digits.'
      )
      .nullable(),
    markPackLoading: Yup.string().trim().max(80, 'Max length allowed is 80.'),
    includeProduct: Yup.string()
      .when('countryOfOrigin', {
        is: 'US',
        then: Yup.string().required(requiredMessage),
        otherwise: Yup.string().when('countryOfOrigin', {
          is: 'CA',
          then: Yup.string().required(requiredMessage)
        })
      })
      .nullable(),
    isProducer: Yup.string()
      .when('includeProduct', {
        is: 'Y',
        then: Yup.string().required(requiredMessage)
      })
      .nullable(),
    notAProducerOfGoods: Yup.string().when('isProducer', {
      is: 'N',
      then: Yup.string().required(
        'If USMCA Exporter is not Producer, Please specify any one option from the above.'
      )
    }),
    naftaCriteria: Yup.string().nullable(),
    netCost: Yup.string().nullable(),
    dateRangeFrom: Yup.string()
      .when('includeProduct', {
        is: 'Y',
        then: Yup.string()
          .required(requiredMessage)
          .test(
            'dateRangeFrom',
            'Please enter a valid Date Range From in DD/MM/YYYY format.',
            function () {
              let dateValue = this.parent['dateRangeFrom']
              if (dateValue) {
                var formattedDate = moment(dateValue, 'DD/MM/YYYY', true)
                if (formattedDate.isValid()) {
                  return true
                }
              }
            }
          )
      })
      .nullable(),
    dateRangeTo: Yup.string()
      .when('includeProduct', {
        is: 'Y',
        then: Yup.string()
          .required(requiredMessage)
          .test(
            'dateRangeTo',
            'Please enter a valid Date Range To in DD/MM/YYYY format.',
            function () {
              let dateValue = this.parent['dateRangeTo']
              if (dateValue) {
                var formattedDate = moment(dateValue, 'DD/MM/YYYY', true)
                if (formattedDate.isValid()) {
                  return true
                }
              }
            }
          )
      })
      .nullable(),
    manufacturingPartyName: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60.'),
    manufacturingMID: Yup.string().trim().max(20, 'Max length allowed is 20.'),
    manufacturingFDARegNum: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60.'),
    growerPartyName: Yup.string().trim().max(60, 'Max length allowed is 60.'),
    growerMID: Yup.string().trim().max(20, 'Max length allowed is 20.'),
    growerFDARegNumber: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60.'),
    consolidatorPartyName: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60.'),
    consolidatorMID: Yup.string().trim().max(20, 'Max length allowed is 20.'),
    consolidatorFDARegNumber: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60.'),
    usorcaMaster: Yup.string().required(requiredMessage).nullable(),
    hsCode: Yup.string().max(15, 'Max length allowed is 15.').nullable()
  })
}

export default makeValidationSchema
