import React, { useState } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import DialogForm from '../../../components/aciacehighway/DialogForm'
import { Card, Typography, useTheme } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import InvoiceDetailsFormProperties from './InvoiceDetailsFormProperties'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function InvoiceDetailsTable() {
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    invoices: []
  })
  const [formDialog, setFormDialog] = useState({
    open: false,
    invoiceDetail: null
  })
  const { getValues, setValue, watch } = useFormContext()
  const invDetails = watch('invDetails')

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    const invoiceDetails = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.TITLE
    )
    const partNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.PART_NO
    )
    const description = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.DESCRIPTION
    )
    const hsCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.HS_CODE
    )
    const qtyInvoiced = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.QTY_INVOICED
    )
    const unitPrice = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.UNIT_PRICE
    )
    const lineAmount = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.LINE_AMOUNT
    )
    const lineItemTotal = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.LINE_ITEM_TOTAL
    )

    return {
      invoiceDetails,
      partNo,
      description,
      hsCode,
      qtyInvoiced,
      unitPrice,
      lineAmount,
      lineItemTotal
    }
  }

  const columns = [
    {
      title: translatedTextsObject.partNo,
      field: 'partNo'
    },
    {
      title: translatedTextsObject.hsCode,
      field: 'hsCode'
    },
    {
      title: translatedTextsObject.description,
      field: 'description'
    },
    {
      title: translatedTextsObject.qtyInvoiced,
      field: 'qtyInvoiced'
    },
    {
      title: translatedTextsObject.unitPrice,
      field: 'unitPrice'
    },
    {
      title: translatedTextsObject.lineAmount,
      field: 'lineAmount'
    },
    {
      title: translatedTextsObject.lineItemTotal,
      field: 'lineItemTotal'
    }
  ]

  function handleAddInvoiceDetail(data) {
    const invDetails = [...getValues('invDetails'), data].map(
      (invoice, index) => ({ ...invoice, _id: index })
    )

    setValue('invDetails', invDetails)
    setFormDialog({ open: false, invoiceDetail: null })
  }

  function getClonedInvoiceDetail(invoiceDetail) {
    let result = { ...invoiceDetail, id: undefined }

    result.servicePromotion.forEach((promotion) => {
      promotion['id'] = undefined
    })

    return result
  }

  function handleDeleteInvoiceDetail() {
    if (_.isEmpty(confirmDialog.invoices)) return

    const _ids = confirmDialog.invoices.map((invoice) => invoice._id)
    const invDetails = [...getValues('invDetails')].filter(
      (invoice) => !_ids.includes(invoice._id)
    )

    setValue('invDetails', invDetails)
    setConfirmDialog({ open: false, invoices: [] })
  }

  function handleEditInvoiceDetail(data) {
    const invDetails = [...getValues('invDetails')]
    const index = invDetails.findIndex((invoice) => invoice._id === data._id)

    invDetails[index] = data

    setValue('invDetails', invDetails)
    setFormDialog({ open: false, invoiceDetail: null })
  }

  return (
    <>
      <Card variant='outlined'>
        <LocalTable
          actions={[
            {
              color: 'primary',
              label: 'Create new line item',
              icon: ['fal', 'plus-circle'],
              onClick: () => setFormDialog({ open: true, invoiceDetail: null })
            }
          ]}
          columns={columns}
          data={invDetails}
          header={
            <Typography variant='h5' style={{ fontWeight: 600 }}>
              {translatedTextsObject.invoiceDetails}
            </Typography>
          }
          moreActions={[
            {
              icon: <FontAwesomeIcon fixedWidth icon={['fal', 'copy']} />,
              label: 'Clone',
              onClick: (rowData) =>
                handleAddInvoiceDetail(getClonedInvoiceDetail(rowData))
            },
            {
              icon: <FontAwesomeIcon fixedWidth icon={['fal', 'trash']} />,
              label: 'Delete',
              onClick: (rowData) =>
                setConfirmDialog({ open: true, invoices: [rowData] })
            }
          ]}
          rowActions={[
            {
              icon: ['fal', 'pen'],
              onClick: (rowData) =>
                setFormDialog({ open: true, invoiceDetail: rowData }),
              tooltip: 'Edit'
            },
            {
              icon: ['fal', 'trash'],
              onClick: (rowData) =>
                setConfirmDialog({ open: true, invoices: [rowData] }),
              tooltip: 'Delete'
            }
          ]}
          select
          selectActions={[
            {
              icon: ['fal', 'trash'],
              tooltip: `Delete`,
              label: 'Delete',
              onClick: (selectedRows) =>
                setConfirmDialog({ open: true, invoices: selectedRows }),
              buttonProps: {
                style: {
                  borderColor: theme.palette.error.main,
                  color: theme.palette.error.main
                }
              }
            }
          ]}
        />
      </Card>
      <DialogForm
        formProperties={InvoiceDetailsFormProperties}
        fullWidth
        maxWidth='lg'
        open={formDialog.open}
        onClose={() => setFormDialog({ open: false, invoiceDetail: null })}
        onSubmit={
          formDialog.invoiceDetail
            ? handleEditInvoiceDetail
            : handleAddInvoiceDetail
        }
        title={`${formDialog.invoiceDetail ? 'Edit' : 'Add new'} line item`}
        value={formDialog.invoiceDetail}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, invoices: [] })}
        onCancel={() => setConfirmDialog({ open: false, invoices: [] })}
        onConfirm={handleDeleteInvoiceDetail}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default InvoiceDetailsTable
