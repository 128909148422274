import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './CiFormFormProperties'
import CiFormFieldsPlaceholder from './CiFormFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Box, Card, Divider, Grid } from '@material-ui/core'

const {
  button: { CngButton }
} = components

function CiFormSection() {
  const generatedIndex = useRef(0)
  const { getValues, setValue, watch } = useFormContext()

  const ciForm = watch('ciForm')

  function handleAddCiForm(data) {
    const ciForm = [...getValues('ciForm'), data]

    setValue('ciForm', ciForm)
  }

  function handleDeleteCiForm(index) {
    const ciForm = [...getValues('ciForm')]

    ciForm.splice(index, 1)

    setValue('ciForm', ciForm)
  }

  function handleEditCiForm(index, data) {
    const ciForm = [...getValues('ciForm')]

    ciForm[index] = data

    setValue('ciForm', ciForm)
  }

  return (
    <Card variant='outlined'>
      <Box padding={2}>
        <Grid container spacing={2}>
          {_.isEmpty(ciForm) ? (
            <Grid item xs={12}>
              <CiFormFieldsPlaceholder onAdd={handleAddCiForm} />
            </Grid>
          ) : (
            ciForm.map((item, index) => (
              <React.Fragment key={++generatedIndex.current}>
                <Grid item xs={12}>
                  <CiFormEntry
                    ciForm={item}
                    onDelete={() => handleDeleteCiForm(index)}
                    onEdit={(data) => handleEditCiForm(index, data)}
                  />
                </Grid>
                {index + 1 !== ciForm.length && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </React.Fragment>
            ))
          )}
        </Grid>
      </Box>
    </Card>
  )
}

function CiFormEntry(props) {
  const { ciForm, onDelete, onEdit } = props

  const methods = useForm({ defaultValues: ciForm })
  const {
    formState: { isDirty }
  } = methods

  function handleEditCiForm(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          <Grid justify='flex-end' container spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditCiForm)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => onDelete(ciForm)}
                  size='medium'
                >
                  Remove
                </CngButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default CiFormSection
