import { Yup } from 'cng-web-lib'
import moment from 'moment'

function makeValidationSchema() {
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!`^_:;.?()[]{}\'"'
  const regexPhoneNum = '^[0-9\\-\\+\\(\\)\\ ]+'
  const errMsgPhoneNum =
    'Invalid Value - Allowed Digits, Parenthesis, Hyphen, + '

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    blanketPeriodFrom: Yup.mixed()
      .nullable()
      .default(null)
      .test('is-date', 'Invalid date format', (value) => {
        if (value === null || value === '') {
          return true // Allow null or empty fields
        }
        return moment(value, 'YYYY-MM-DD', true).isValid()
      }),
    blanketPeriodTo: Yup.mixed()
      .nullable()
      .default(null)
      .test('is-date', 'Invalid date format', (value) => {
        if (value === null || value === '') {
          return true // Allow null or empty fields
        }
        return moment(value, 'YYYY-MM-DD', true).isValid()
      }),
    company: validateStringField(100, regexAlphaNumeric, errMsgAlphaNumeric),
    naftaTitle: validateStringField(50, regexAlphaNumeric, errMsgAlphaNumeric),
    naftaDate: Yup.mixed()
      .nullable()
      .default(null)
      .test('is-date', 'Invalid date format', (value) => {
        if (value === null || value === '') {
          return true // Allow null or empty fields
        }
        return moment(value, 'YYYY-MM-DD', true).isValid()
      }),
    partyName: validateStringField(60, regexAlphaNumeric, errMsgAlphaNumeric),
    contactTelphNum: validateStringField(50, regexPhoneNum, errMsgPhoneNum),
    contactFax: validateStringField(50, regexPhoneNum, errMsgPhoneNum),
    email: Yup.string()
      .nullable()
      .email('Invalid Email Id')
      .max(35, 'Max length allowed is 35'),
    certifierType: Yup.string().nullable()
  })
}

export default makeValidationSchema
