import makeValidationSchema from './InvTradePartyMakeValidationSchema'
import { components, useTranslation, constants, useServices } from 'cng-web-lib'
import React, { useState, useEffect } from 'react'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import { Divider, Grid, Typography, InputAdornment, IconButton, Box } from '@material-ui/core'
import { NaStateCodeAutoCompleteField } from '../../components/na'
import { useFormContext } from 'react-hook-form'
import Alert from '../../components/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TemplateSelectDialog from '../../components/aciacehighway/TemplateSelectDialog'
import PartyMasterApiUrls from '../../apiUrls/NBCIUSCAPartyMasterApiUrls'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { NaNbciCountryCodeAutocompleteField, NaNbciPartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { fetchUser } from 'src/views/userprofile/UserProfileService.js'

const {
  form: {
    adapter: {
      useFormAdapter: { useField }
    },
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
      CngCheckboxField,
    },
  },
  CngGridItem,
} = components
const {
  filter: { LIKE, EQUAL, IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyId: "",
  partyName: "",
  identificationCode: "",
  fdaRegNum: "",
  address: "",
  city: "",
  countryCode: "",
  stateCode: "",
  postalCode: "",
  contactName: "",
  contactTelphNum: "",
  contactFax: "",
  contactEmail: "",
  partyType: "",
  dba: "",
  partyidentifier: "",
  saveFlag: false,
  upsScs: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, getValues, reset, trigger } = useFormContext();
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { watch } = useFormContext()

  const saveFlag = watch('saveFlag');
  const partyType = watch('partyType')
  const countryCode = watch('countryCode')
  const [templateDialog, setTemplateDialog] = useState(false)
  const [lookups, setLookups] = useState(null)
  const [partyType1] = useField("partyType");
  const invTradeParty = watch('invTradeParty')
  let showPartyTypeValue = partyType1.value
  const [inputPartyIDField, setInputPartyIDField] = useState();
  /* useEffect(() => {
    if (partyType === "VN") {
      setValue('partyidentifier', "IRS")
    }
    if (partyType === "BY") {
      setValue('partyidentifier', "BN")
    }
    if (partyType === "EX") {
      setValue('partyidentifier', "IRS")
    }
    if (partyType === "CN") {
      setValue('partyidentifier', "GST")
    }
    if (partyType === "MF") {
      setValue('partyidentifier', "IRS")
    }
  }, [partyType]) */

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const { securedSendRequest } = useServices();
  const [user, setUser] = useState([]);

  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useEffect(() => {
    var upsClID = "";
    if (user && Object.keys(user).length > 0) {
      console.log("upsScsClientId" + JSON.stringify(user.upsSacClientId));
      if (user.upsSacClientId) {
        user.upsSacClientId.forEach((e) => {
          setValue('upsScsClientId', e.upsSacClientId, { shouldValidate: true });
          upsClID = e.upsSacClientId;
        })
      }
      if (upsClID) {
        if (partyType == "BY") {
          if (upsClID.indexOf("~") !== -1) {
            if (upsClID
              .substring(upsClID.indexOf("~") + 1,
                upsClID.length)
              .length > 0) {
              upsClID = upsClID.substring(
                upsClID.indexOf("~") + 1,
                upsClID.length);
            } else {
              upsClID = upsClID.substring(0,
                upsClID.indexOf("~"));
            }
          }
        }
        setValue("upsScs", upsClID)
      }
    }
  }, [user, invTradeParty, partyType])

  const columns = [
    {
      field: 'inputPartyID',
      title: translatedTextsObject.inputPartyID
    },
    {
      field: 'partyType',
      title: translatedTextsObject.partyType,
      render: data =>

        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('partyType', data.partyType)}
        </Typography>
    },
    {
      field: 'partyName',
      title: translatedTextsObject.partyName,
    },
    {
      title: translatedTextsObject.partyAddress,
      render: data => [data.address1, data.address2, data.address3].join(' ')
    }

  ]
  const sortOptions = [
    {
      label: translatedTextsObject.inputPartyID,
      value: 'inputPartyID'
    },
    {
      label: translatedTextsObject.partyType,
      value: 'partyType'
    },
  ]
  function handleApplyTemplate(template) {
    if (template) {
      const {
        inputPartyID,
        partyType,
        partyName,
        address1,
        nameDBA,
        fdaRegNum,
        cityName,
        stateCode,
        postalCode,
        countryCode,
        faxNo,
        contactPerson,
        telNo,
        email,
        secondIdentificationCode,
        partyIdentifierSection
      } = template

      const partyIdentifierCode = partyIdentifierSection && partyIdentifierSection.length > 0 ? partyIdentifierSection[0].partyIdentifierCode : "";
      const partyIdentifierNumber = partyIdentifierSection && partyIdentifierSection.length > 0 ? partyIdentifierSection[0].partyIdentifierNumber : "";

      setInputPartyIDField(inputPartyID)
      setValue('partyType', partyType, { shouldDirty: true })
      setValue('partyName', partyName || '', { shouldDirty: true })
      setValue('partyId', inputPartyID || '', { shouldDirty: true })
      setValue('dba', nameDBA || '', { shouldDirty: true })
      setValue('fdaRegNum', fdaRegNum || '', { shouldDirty: true })
      setValue('address', address1 || '', { shouldDirty: true })
      setValue('city', cityName || '', { shouldDirty: true })
      setValue('stateCode', stateCode || '', { shouldDirty: true })
      setValue('countryCode', countryCode || '', { shouldDirty: true })
      setValue('postalCode', postalCode || '', { shouldDirty: true })
      setValue('partyidentifier', partyIdentifierCode, { shouldDirty: true })
      setValue('identificationCode', partyIdentifierNumber, { shouldDirty: true })
      setValue('contactName', contactPerson || '', { shouldDirty: true })
      setValue('contactTelphNum', telNo || '', { shouldDirty: true })
      setValue('contactFax', faxNo || '', { shouldDirty: true })
      setValue('contactEmail', email || '', { shouldDirty: true })
      setValue('secondIdentificationCode', secondIdentificationCode || '', { shouldDirty: true })

      trigger()
    } else {
      reset()
    }
  }
  function makeTranslatedTextsObject() {
    const partyId = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTY_ID
    )
    const partyName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTY_NAME
    )
    const identificationCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.IDENTIFICATION_CODE
    )
    const fdaRegNum = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.FDA_REG_NUM
    )
    const address = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.ADDRESS
    )
    const city = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.CITY
    )
    const countryCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.COUNTRY_CODE
    )
    const stateCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.STATE_CODE
    )
    const postalCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.POSTAL_CODE
    )
    const contactName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.CONTACT_NAME
    )
    const contactTelphNum = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.CONTACT_TELPH_NUM
    )
    const contactFax = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.CONTACT_FAX
    )
    const contactEmail = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.CONTACT_EMAIL
    )
    const partyType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTY_TYPE
    )
    const dba = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.DBA
    )
    const partyidentifier = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTYIDENTIFIER
    )
    const secondIdentificationCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.SECOND_IDENTIFICATION_CODE
    )
    const autoFill = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.AUTOFILL
    )
    const inputPartyID = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTYID
    )
    const partyAddress = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTY_ADDRESS
    )
    const saveFlag = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.SAVE_FLAG
    )
    let upsScs = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.UPSSCS
    )


    return {
      partyId,
      partyType,
      dba,
      partyName,
      fdaRegNum,
      identificationCode,
      address,
      city,
      countryCode,
      stateCode,
      postalCode,
      contactName,
      contactTelphNum,
      contactFax,
      contactEmail,
      partyidentifier,
      secondIdentificationCode,
      autoFill,
      inputPartyID,
      partyAddress,
      saveFlag,
      upsScs
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: 600 }}>
            Party Information
          </Typography>
          <Typography color='textSecondary' variant='caption'>
            <Typography variant='inherit' color='error'>
              *
            </Typography>
            Mandatory fields
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.saveFlag}>
              <CngCheckboxField
                label={
                  <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                    {translatedTextsObject.saveFlag}
                  </Typography>
                }
                name='saveFlag'
                disabled={disabled}
                onChange={(e) => {
                  setValue('saveFlag', e.target.checked)

                  if (!e.target.checked && undefined === inputPartyIDField) {
                    setValue("partyId", "")
                  }
                }}
                size='small'
                style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm shouldHide={shouldHideMap?.partyId}>
              <CngTextField
                required
                name="partyId"
                inputProps={{
                  style: { textTransform: 'uppercase' },
                  maxLength: 35
                }}
                placeholder={translatedTextsObject.partyId}
                disabled={disabled || !saveFlag}
                onChange={(e) => {
                  setValue("partyId", e.target.value.toUpperCase(), { shouldValidate: true })
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm='auto'>
              <Divider orientation='vertical' />
            </CngGridItem>
            <CngGridItem xs={12} sm={4}>
              <NaNbciPartyMasterAutoCompleteField
                name="partyDropDown"
                label={translatedTextsObject.autoFill}
                disabled={disabled}
                onChange={(_, options) => handleApplyTemplate(options.data)}
                size='small'
                fullWidth
                disableClearable
                textFieldProps={{
                  InputProps: {
                    customEndAdornment: () => (
                      <InputAdornment position='end' style={{ marginTop: -16 }}>
                        <IconButton onClick={(event) => {
                          event.stopPropagation()
                          setTemplateDialog(true)
                        }}>
                          <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                            <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                          </Box>
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                }}
                forcePopupIcon={false}
                lookupProps={{
                  filters: [{
                    field: 'partyId',
                    operator: EQUAL,
                    value: FileForUserGetPartyId()
                  },
                  { field: 'usorcaMaster', operator: EQUAL, value: 'CA' }
                    // { field: 'partyType', operator: IN, value: ['VN', 'BY', 'EX', 'CN', 'MF', 'OP', 'DP', 'BP', 'NT', 'SH', 'CE', 'IR'] }
                  ]
                }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.partyType}>
              <CngCodeMasterAutocompleteField
                codeType='NBCI_CA_INV_PARTY_TYPE'
                name='partyType'
                key={partyType}
                label={translatedTextsObject.partyType}
                disabled={disabled}
                isRequired
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={4} shouldHide={showPartyTypeValue !== "BY"}>
              <CngTextField
                size='small'
                required
                name='upsScs'
                label={translatedTextsObject.upsScs}
                disabled={true}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={8} shouldHide={shouldHideMap.dba}>
              <CngTextField
                name='dba'
                label={translatedTextsObject.dba}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.partyName}>
              <CngTextField
                name='partyName'
                required
                label={translatedTextsObject.partyName}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.fdaRegNum}>
              <CngTextField
                name='fdaRegNum'
                label={translatedTextsObject.fdaRegNum}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyidentifier}>
              <CngCodeMasterAutocompleteField
                name='partyidentifier'
                label={translatedTextsObject.partyidentifier}
                disabled={disabled}
                key={getValues("partyidentifier")}
                codeType='NBCI_CA_INV_PARTY_IDENTIFIER'
                required={partyType == "BY" || partyType == "VN"}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.identificationCode}>
              <CngTextField
                name='identificationCode'
                label={translatedTextsObject.identificationCode}
                disabled={disabled}
                required={partyType == "BY" || partyType == "VN"}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} shouldHide={shouldHideMap.address}>
              <CngTextField
                required
                name='address'
                label={translatedTextsObject.address}
                disabled={disabled}
                isRequired
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.city}>
              <CngTextField
                name='city'
                label={translatedTextsObject.city}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.countryCode}>
              <NaNbciCountryCodeAutocompleteField
                name='countryCode'
                label={translatedTextsObject.countryCode}
                disabled={disabled}
                lookupProps={{
                  label: (record) => `${record.code};${record.descriptionEn}`
                }}
                key={countryCode}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.stateCode}>
              <NaStateCodeAutoCompleteField
                name='stateCode'
                countryCode={countryCode}
                key={countryCode}
                label={translatedTextsObject.stateCode}
                disabled={disabled}
                lookupProps={{
                  label: (record) => `${record.code};${record.descriptionEn}`
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.postalCode}>
              <CngTextField
                name='postalCode'
                label={translatedTextsObject.postalCode}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='flex-end'>
                <Alert
                  severity='info'
                  icon={<FontAwesomeIcon icon={['fal', 'info-circle']} size='xs' />}
                >
                  <Typography style={{ lineHeight: 1.2 }} variant='caption'>
                    <Typography style={{ fontWeight: 600 }} variant='inherit'>
                      NOTE:&nbsp;
                    </Typography>
                    Postal Code for Canada should be: i.e.: J7V1B1, J7V 1B1, J7V-1B1
                  </Typography>
                </Alert>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid alignItems='center' container spacing={2}>
            <Grid item xs='auto'>
              <Typography variant='body2' style={{ fontWeight: 600 }}>
                Local Contact Information
              </Typography>
            </Grid>
            <Grid item xs>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} shouldHide={shouldHideMap.contactName}>
                <CngTextField
                  name='contactName'
                  label={translatedTextsObject.contactName}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.contactTelphNum}>
                <CngTextField
                  name='contactTelphNum'
                  label={translatedTextsObject.contactTelphNum}
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    let word = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters

                    if (word.length <= 3) {
                      word = word.substring(0, 3); // First 3 digits
                    } else if (word.length <= 6) {
                      word = word.substring(0, 3) + '-' + word.substring(3, 6); // Format as NNN-NNN
                    } else {
                      word = word.substring(0, 3) + '-' + word.substring(3, 6) + '-' + word.substring(6, 10); // Format as NNN-NNN-NNNN
                    }

                    setValue('contactTelphNum', word); // Set the formatted value
                  }}
                  placeholder={'NNN-NNN-NNNN'}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.contactFax}>
                <CngTextField
                  name='contactFax'
                  label={translatedTextsObject.contactFax}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.contactEmail}>
                <CngTextField
                  name='contactEmail'
                  label={translatedTextsObject.contactEmail}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} shouldHide={shouldHideMap.secondIdentificationCode}>
                <CngTextField
                  name='secondIdentificationCode'
                  label={translatedTextsObject.secondIdentificationCode}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={9}>
                <Box display='flex' justifyContent='flex-end'>
                  <Alert
                    severity='info'
                    icon={<FontAwesomeIcon icon={['fal', 'info-circle']} size='xs' />}
                  >
                    <Typography style={{ lineHeight: 1.2 }} variant='caption'>
                      <Typography style={{ fontWeight: 600 }} variant='inherit'>
                        NOTE:&nbsp;
                      </Typography>
                      1) If the Country is United States (US) or Canada (CA), then City Name,Province/State, Postal/Zip codes will be mandatory.
                    </Typography>
                  </Alert>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={7}>
                <Box display='flex' justifyContent='flex-end'>
                  <Alert
                    severity='info'
                    icon={<FontAwesomeIcon icon={['fal', 'info-circle']} size='xs' />}
                  >
                    <Typography style={{ lineHeight: 1.2 }} variant='caption'>
                      <Typography style={{ fontWeight: 600 }} variant='inherit'>
                        NOTE:&nbsp;
                      </Typography>
                      IRS or SSN Format(Eg: 12-3456789 OR 12-3456789-C4 OR 123-45-6789 OR 123456-12345)
                    </Typography>
                  </Alert>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={15} sm={6}>
                <Box display='flex' justifyContent='flex-end'>
                  <Alert
                    severity='info'
                    icon={<FontAwesomeIcon icon={['fal', 'info-circle']} size='xs' />}
                  >
                    <Typography style={{ lineHeight: 1.2 }} variant='caption'>
                      <Typography style={{ fontWeight: 600 }} variant='inherit'>
                        NOTE:&nbsp;
                      </Typography>
                      Business number should be as NNNNNNNNNRMNNNN ( Eg : 123456789RM1234)
                    </Typography>
                  </Alert>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'partyId', direction: 'ASC' }]}
        fetch={{ url: PartyMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
          { field: 'usorcaMaster', operator: EQUAL, value: ['CA'] }
          // { field: 'partyType', operator: IN, value: ['VN', 'BY', 'EX', 'CN', 'MF', 'OP', 'DP', 'BP', 'NT', 'SH', 'CE', 'IR'] }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'inputPartyID', operator: LIKE }}
        sortOptions={sortOptions}
        title='Template'
      />
    </>
  )
}

const InvTradePartyFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default InvTradePartyFormProperties
