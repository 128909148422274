import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!`^_:;.?()[]{}\'"'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    reference: Yup.string().nullable(),
    referenceNumber: validateStringField(
      30,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    )
  })
}

export default makeValidationSchema
