import React from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import NBCICanadaInvoiceApiUrls from '../../apiUrls/NBCICanadaInvoiceApiUrls'
import FormProperties from './FormProperties'
import InvoiceDetailsTable from './invoiceDetails/InvoiceDetailsTable'
import InvoiceSummarySection from './invoiceSummary/InvoiceSummarySection'
import Grid from '@material-ui/core/Grid'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngForm }
} = components

const { FormState } = constants

function InvoiceDetailsAndSummaryInvoiceSection(props) {
  const {
    invoiceData,
    onBack,
    onDiscard,
    onSetInvoiceData,
    onSetLoading,
    onSkip,
    onSubmit,
    showNotification
  } = props

  const { updateRecord } = useServices()

  function handleSave(data) {
    onSetLoading(true)

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    updateRecord.execute(
      NBCICanadaInvoiceApiUrls.PUT,
      FormProperties.toServerDataFormat(data),
      (response) => {
        showNotification('success', 'Invoice updated.')
        onSetInvoiceData(response)
      },
      (error) => {
        console.log(error, error.response)
        const { data } = error.response

        if (data && data.errorMessages) {
          showNotification('error', data.errorMessages)
        }
      },
      () => onSetLoading(false)
    )
  }

  return (
    <CngForm
      formikProps={{
        initialValues: invoiceData,
        makeValidationSchema: FormProperties.formikProps.makeValidationSchema,
        onSubmit: handleSave
      }}
      formState={FormState.COMPLETED}
      innerForm
      renderBodySection={() => (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InvoiceDetailsTable />
          </Grid>
          <Grid item xs={12}>
            <InvoiceSummarySection invoiceDetails={invoiceData.invoiceDetails}/>
          </Grid>
        </Grid>
      )}
      renderButtonSection={() => (
        <ButtonSection
          onBack={onBack}
          onDiscard={onDiscard}
          onSkip={onSkip}
          onSubmit={onSubmit}
        />
      )}
    />
  )
}

function ButtonSection(props) {
  const { onBack, onDiscard, onSkip, onSubmit } = props

  return (
    <Grid container justify='space-between' spacing={3}>
      <Grid item xs={12} md='auto'>
        <CngButton color='secondary' onClick={onDiscard} size='medium'>
          Discard
        </CngButton>
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onBack} size='medium'>
              Back
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onSkip} size='medium'>
              Skip
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='primary' size='medium' type='submit'>
              Save as Draft
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='primary' onClick={onSubmit} size='medium'>
              Submit
            </CngButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvoiceDetailsAndSummaryInvoiceSection
