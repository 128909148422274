import { constants,Yup } from 'cng-web-lib'
import moment from 'moment'
import Namespace from '../../../constants/locale/Namespace'


const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!`^_:;.?()[]{}\'"'

  const regexDigit = '^[0-9]*$'
  const regexDigitWith7Decimal = '^[0-9]{0,7}(.[0-9]{0,7})?$'
  const errMsgDigit = 'Invalid Value - Allowed Digits'
  const errMsgDigitWith7Decimal =
  'Please enter only numeric values with up to 7 digits and 7 decimal'
  const  errMsgDecimal1="Only numeric values with up to"
  const errMsgDecimal2 ="digits follow by"
  const  errMsgDecimal3 ="decimal points"
  const errMsgMaxLength = "Max length allowed is"
  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    subProbillNo: validateStringField(3, regexDigit, errMsgDigit),
    productId: validateStringField(50, regexAlphaNumeric, errMsgAlphaNumeric),
    partNo: validateStringField(40, regexAlphaNumeric, errMsgAlphaNumeric),
    hsCode: validateStringField(13, regexDigit, errMsgDigit),
    countryOfOrigin: Yup.string().nullable(),
    stateOfOrigin: Yup.string().nullable(),
    // qtyInvoiced: validateStringField(11, regexDigitWith7Decimal, errMsgDigitWith7Decimal),
    qtyUom: Yup.string().nullable(),
    // unitPrice: validateStringField(15, regexDigitWith7Decimal, errMsgDigitWith7Decimal),
    description: validateStringField(
      160,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    purchaseOrderNo: validateStringField(
      48,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    noOfPkgs: validateStringField(10, regexDigit, errMsgDigit),
    noOfPkgsUom: Yup.string().nullable(),
    // lineItemTotal: validateStringField(16, regexDigitWith7Decimal, errMsgDigitWith7Decimal),
    netWeight: Yup.string().nullable().matches(/^\d{0,10}(\.\d{0,4})?$/, errMsgDecimal1 + ' 10 ' + errMsgDecimal2 + ' 4 ' + errMsgDecimal3).max(14, errMsgMaxLength + ' 14'),
    netWeightUom: Yup.string().nullable(),
    grossWeight: Yup.string().nullable().matches(/^\d{0,10}(\.\d{0,4})?$/, errMsgDecimal1 + ' 10 ' + errMsgDecimal2 + ' 4 ' + errMsgDecimal3).max(14, errMsgMaxLength + ' 14'),
    grossWeightUom: Yup.string().nullable(),
    lineAmount: Yup.string().nullable(),
    naftaCriteria: Yup.string().nullable(),
    dateRangeFrom: Yup.mixed()
      .nullable()
      .default(null)
      .test('is-date', 'Invalid date format', (value) => {
        if (value === null || value === '') {
          return true // Allow null or empty fields
        }
        return moment(value, 'YYYY-MM-DD', true).isValid()
      }),
    dateRangeTo: Yup.mixed()
      .nullable()
      .default(null)
      .test('is-date', 'Invalid date format', (value) => {
        if (value === null || value === '') {
          return true // Allow null or empty fields
        }
        return moment(value, 'YYYY-MM-DD', true).isValid()
      }),
    netCost: Yup.string().nullable(),
    isProducer: Yup.string().nullable(),
    includeProdNafata: Yup.string().nullable(),
    notAProducerOfGoods: Yup.string().nullable()
  })
}

export default makeValidationSchema
