import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!`^_:;.?()[]{}\'"'
  const regexDigitWithDecimal42 = '^[0-9]{0,4}(.[0-9]{0,2})?$'
  const errMsgDigitWithDecimal42 =
    'Please enter only numeric values with up to 4 digits and 2 decimal'
  const regexDigitWithDecimal62 = '^[0-9]{0,6}(.[0-9]{0,2})?$'
  const errMsgDigitWithDecimal62 =
    'Please enter only numeric values with up to 6 digits and 2 decimal'
  const regexDigitWithDecimal92 = '^[0-9]{0,9}(.[0-9]{0,2})?$'
  const errMsgDigitWithDecimal92 =
    'Please enter only numeric values with up to 9 digits and 2 decimal'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    containerNo: validateStringField(30, regexAlphaNumeric, errMsgAlphaNumeric),
    freightCharges: Yup.string().nullable(),
    placeOfLadding: validateStringField(
      30,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    deliveryInstructions: validateStringField(
      50,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    codAmount: Yup.string()
      .nullable()
      .matches(regexDigitWithDecimal42, errMsgDigitWithDecimal42),
    currency: Yup.string().nullable(),
    declareValueOfShipment: Yup.string()
      .nullable()
      .matches(regexDigitWithDecimal92, errMsgDigitWithDecimal92),
    chargeTo: validateStringField(100, regexAlphaNumeric, errMsgAlphaNumeric),
    releaseValue: Yup.string()
      .nullable()
      .matches(regexDigitWithDecimal62, errMsgDigitWithDecimal62)
  })
}

export default makeValidationSchema
