import makeValidationSchema from './NaftaFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../../constants/locale/key/NBCICanadaInvoice'
import Grid from '@material-ui/core/Grid'
import moment from 'moment-timezone'

const {
  form: {
    field: { CngSelectField, CngTextField, CngDateField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  blanketPeriodFrom: '',
  blanketPeriodTo: '',
  company: 'COMPANY',
  companyTitle: '',
  naftaDate: moment().tz("Canada/Eastern").format('YYYY-MM-DD'),
  partyName: 'NAME',
  contactTelphNum: '',
  contactFax: '',
  email: '',
  certifierType: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const blanketPeriodFrom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.BLANKET_PERIOD_FROM
    )
    const blanketPeriodTo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.BLANKET_PERIOD_TO
    )
    const company = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.COMPANY
    )
    const companyTitle = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.NAFTA_TITLE
    )
    const naftaDate = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.NAFTA_DATE
    )
    const partyName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.PARTY_NAME
    )
    const contactTelphNum = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.CONTACT_TELPH_NUM
    )
    const contactFax = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.CONTACT_FAX
    )
    const email = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.EMAIL
    )
    const certifierType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.CERTIFIER_TYPE
    )

    return {
      blanketPeriodFrom,
      blanketPeriodTo,
      company,
      companyTitle,
      naftaDate,
      partyName,
      contactTelphNum,
      contactFax,
      email,
      certifierType
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.blanketPeriodFrom}>
        <CngDateField
          name='blanketPeriodFrom'
          label={translatedTextsObject.blanketPeriodFrom}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.blanketPeriodTo}>
        <CngDateField
          name='blanketPeriodTo'
          label={translatedTextsObject.blanketPeriodTo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.certifierType}>
        <CngSelectField
          name='certifierType'
          label={translatedTextsObject.certifierType}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Importer', value: 'IR' },
            { text: 'Exporter', value: 'EX' },
            { text: 'Producer', value: 'MP' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.partyName}>
        <CngTextField
          name='partyName'
          label={translatedTextsObject.partyName}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.company}>
        <CngTextField
          name='company'
          label={translatedTextsObject.company}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.companyTitle}>
        <CngTextField
          name='companyTitle'
          label={translatedTextsObject.companyTitle}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.contactTelphNum}>
        <CngTextField
          name='contactTelphNum'
          label={translatedTextsObject.contactTelphNum}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.contactFax}>
        <CngTextField
          name='contactFax'
          label={translatedTextsObject.contactFax}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.email}>
        <CngTextField
          name='email'
          label={translatedTextsObject.email}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.naftaDate}>
        <CngDateField
          name='naftaDate'
          label={translatedTextsObject.naftaDate}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const NaftaFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default NaftaFormFormProperties
