import React, { useEffect, useState } from 'react'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import { Grid, Card, CardContent, Divider, Snackbar, SnackbarContent, Typography, Box,IconButton,InputAdornment } from '@material-ui/core'
import { components, DataFlattener, useServices, useTranslation,DateTimeFormatter ,constants} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import TradePartySection from './TradePartySection'
import CollapsibleSection from '../../components/ui/CollapsibleSection'
import CiFormSection from './CiFormSection'
import { useFormContext , useWatch} from 'react-hook-form'
import { Close as CloseIcon } from '@material-ui/icons'
import _ from 'lodash'
import { fetchUser } from '../../views/userprofile/UserProfileService.js'
import { FileForUserGetUserDetails } from '../../common/FileForUserCommon'
import AdditionalFormInformationSection from './additionalFormSection/AdditionalFormInformationSection'
import CngSection from 'src/components/cngcomponents/CngSection'
import CAInvoiceLookupsProvider from './lookups/CAInvoiceLookupsContext'
import moment from 'moment-timezone'
import { NaAceHwyPOEPortAutocompleteField } from 'src/components/na/autocomplete/codemaintenance'
import NBCICanadaInvoiceApiUrls from 'src/apiUrls/NBCICanadaInvoiceApiUrls'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NbciCAInvoiceAutoCompleteMasterField from './NbciCAInvoiceAutoCompleteMasterField'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngSelectField,
      CngCodeMasterAutocompleteField,
      CngCheckboxField
    },
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  invoiceNumber: "",
  templateNameDropDown: '',
  invoiceDate: moment().tz("Canada/Eastern").format('YYYY-MM-DD'),
  purchaseOrderNum: "",
  currencey: "CAD",
  tripVoyageNo: "",
  shipmentDate: moment().tz("Canada/Eastern").format('YYYY-MM-DD'),
  entryNumber: "",
  transactionType: "",
  portOfExit: "",
  transportMode: "J",
  carrierCode: "",
  carrierName: "",
  ccn: "",
  grossWeight: "",
  grossWeightUom: "",
  netWeight: "",
  netWeightUom: "",
  marksNumbers: "",
  status: "",
  templateFlag: false,
  templateName: '',
  naftaForm: [],
  bolForm: [],
  invSummary: [],
  shipmentSummary: [],
  shipmentSummaryReference: [],
  packingList: [],
  b13aForm: [],
  invDetails: [],
  invTradeParty: [],
  ciForm: [],
  cfiaForm: [],
  importDeclForm: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled,showNotification, shouldHideMap,onSetLoading }) {
  const { setValue, getValues, watch ,trigger} = useFormContext()
  const [openDialog, setOpenDialog] = useState(true)
  const ciForm = watch('ciForm')
  const currencey = watch('currency')
  const templateFlag = watch('templateFlag')
  const transactionType = watch('transactionType')
  const netWeightUom = watch('netWeightUom')
  const grossWeightUom = watch('grossWeightUom')
  const transportMode = watch('transportMode')
  const portOfExit = watch('portOfExit')

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  function onClose() {
    setOpenDialog(false)
  }
  function makeTranslatedTextsObject() {
    const invoiceNumber = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.INVOICE_NUMBER
    )
    const invoiceDate = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.INVOICE_DATE
    )
    const purchaseOrderNum = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PURCHASE_ORDER_NUM
    )
    const currencey = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CURRENCEY
    )
    const tripVoyageNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.TRIP_VOYAGE_NO
    )
    const shipmentDate = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.SHIPMENT_DATE
    )
    const entryNumber = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ENTRY_NUMBER
    )
    const transactionType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.TRANSACTION_TYPE
    )
    const portOfExit = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PORT_OF_EXIT
    )
    const transportMode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.TRANSPORT_MODE
    )
    const carrierCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CARRIER_CODE
    )
    const carrierName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CARRIER_NAME
    )
    const ccn = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CCN
    )
    const grossWeight = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.GROSS_WEIGHT
    )
    const grossWeightUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.GROSS_WEIGHT_UOM
    )
    const netWeight = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NET_WEIGHT
    )
    const netWeightUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NET_WEIGHT_UOM
    )
    const marksNumbers = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.MARKS_NUMBERS
    )
    const ciForm = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.TITLE
    )
    const templateFlag = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.TEMPLATE_FLAG
    )
    const templateName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.TEMPLATE_NAME
    )
    const templateNameDropDown =translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.TEMPLATE_NAME
    )

    return {
      invoiceNumber,
      invoiceDate,
      carrierCode,
      carrierName,
      purchaseOrderNum,
      tripVoyageNo,
      shipmentDate,
      ccn,
      currencey,
      portOfExit,
      transportMode,
      grossWeight,
      grossWeightUom,
      netWeight,
      netWeightUom,
      entryNumber,
      transactionType,
      marksNumbers,
      ciForm,
      templateFlag,
      templateName,
      templateNameDropDown
    }
  }

  function handleApplyTemplate(template) {
    if (!template) return

    const { data } = template

    const {
      id
    } = data

    let rowData = {
      id: id
    }
    onSetLoading(true)
    securedSendRequest.execute(
      'POST',
      NBCICanadaInvoiceApiUrls.CLONE,
      rowData,
      (response) => {
        var data = response.data
        var errorMessages = data.errorMessages

        if (response.status == 200 && data != null && errorMessages == null) {
          let record = response.data
          setValue('invoiceNumber2', record.invoiceNumber2 || '')
          setValue('probillNo', record.probillNo || '')
          setValue('purchaseOrderNum', record.purchaseOrderNum || '')
          setValue('currencey', record.currencey || '')
          setValue('tripVoyageNo', record.tripVoyageNo || '')
          setValue('entryNumber', record.entryNumber || '')
          setValue('transactionType', record.transactionType || '')
          setValue('portOfExit', record.portOfExit || '')
          setValue('transportMode', record.transportMode || '')
          setValue('carrierCode', record.carrierCode || '')
          setValue('invoiceDate', record.invoiceDate ? DateTimeFormatter.toClientDate(record.invoiceDate) : '')
          setValue('shipmentDate', record.shipmentDate ? DateTimeFormatter.toClientDate(record.shipmentDate) : '')
          setValue('carrierName', record.carrierName || '')
          setValue('ccn', record.ccn || '')
          setValue('billOfLading', record.billOfLading || '')
          setValue('grossWeight', record.grossWeight || '')
          setValue('grossWeightUom', record.grossWeightUom || '')
          setValue('netWeight', record.netWeight || '')
          setValue('netWeightUom', record.netWeightUom || '')
          setValue('marksNumbers', record.marksNumbers || '')
          setValue('remainderFlag', record.remainderFlag || '')
          setValue('remainderNote', record.remainderNote || '')
          setValue('submissionType', record.submissionType || '')

          if (record.invTradeParty) {
            const tradeParty = record.invTradeParty.map(
              (party, index) => ({ ...party, _id: index + 1 })
            )
            setValue('invTradeParty', tradeParty || [])
          }

          setValue('naftaForm', record.naftaForm || null)
          setValue('bolForm', record.bolForm || null)
          setValue('invSummary', record.invSummary || null)
          setValue('packingList', record.packingList || null)
          setValue('invDetails', record.invDetails || [])
          setValue('b13aForm', record.b13aForm ||null)
          setValue('ciForm', record.ciForm || null)
          setValue('cfiaForm', record.cfiaForm || null)
          setValue('importDeclForm', record.importDeclForm || null)
          trigger()
        } else {
          showNotification('error', errorMessages)
        }
        onSetLoading(false)
      },
      (error) => console.error(error)
    )
   
  }

  const { securedSendRequest } = useServices();
  const [user, setUser] = useState([]);
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  const [clientIdSet, isClientIdSet] = useState(true);
  
  useEffect(() => {
    let clientIdExists = false;
    var upsClID="";
    if (user && Object.keys(user).length > 0) {
      if (user.upsSacClientId) {
        user.upsSacClientId.forEach((e) => {
          console.log("inside foreach"+JSON.stringify(user.upsSacClientId));
          upsClID=e.upsSacClientId;
          if(upsClID){
              if(upsClID.indexOf("~") !== -1){
                if (upsClID
                  .substring(upsClID.indexOf("~") + 1,
                  upsClID.length)
                  .length> 0) {
                    upsClID = upsClID.substring(
                      upsClID.indexOf("~") + 1,
                      upsClID.length);
              } else {
                upsClID = upsClID.substring(0,
                  upsClID.indexOf("~"));
              }
              }
            }
          setValue('upsScsClientId', upsClID, { shouldValidate: true });
          clientIdExists = true;
        })
      isClientIdSet(clientIdExists);
    }
  }
  }, [user])

  return (
    <>
      <Snackbar open={!clientIdSet && openDialog} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <SnackbarContent message={
          <>
            <Grid>
              <Typography variant="h4">UPS SCS Client Id is not yet configured.
                  <IconButton 
                    size='small' 
                    onClick={onClose}
                    style={{ position: 'absolute', top: 0, right: 0 }}>
                    <CloseIcon style={{ color: 'white' }} />
                  </IconButton>
              </Typography>

              <Divider style={{ marginTop: '5px', marginBottom: '10px' }} />

              <Typography variant="h5">Please navigate to Shared &gt; User Settings and configure the UPS SCS Client Id under the
              <b> General </b>
              section.</Typography>
            </Grid>
          </>
        } />
      </Snackbar>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Box padding={1}>
            <Grid alignItems='stretch' container spacing={1}>
              <Grid item xs={12} md={4}>
                <NbciCAInvoiceAutoCompleteMasterField
                  name='templateNameDropDown'
                  label='Auto-fill using template'
                  disabled={disabled}
                  onChange={(_, options) => handleApplyTemplate(options)}
                  size='small'
                  fullWidth
                  disableClearable
                  textFieldProps={{
                    InputProps: {
                      customEndAdornment: () => (
                        <InputAdornment position='end' style={{ marginTop: -16 }}>
                          <Box display='flex' alignItems='center' justifyContent='center' width={48} height={48}>
                            <FontAwesomeIcon icon={['fal', 'money-check-edit']} />
                          </Box>
                        </InputAdornment>
                      )
                    }
                  }}
                  forcePopupIcon={false}
                  lookupProps={{
                    filters: [{
                      field: 'partyId',
                      operator: EQUAL,
                      value: FileForUserGetPartyId()
                    }]
                  }}
                />
              </Grid>
              <Grid item xs='auto'>
                <Divider orientation='vertical' />
              </Grid>
              <Grid item xs={12} md>
                <Grid alignItems='center' container spacing={1}>
                  <CngGridItem xs={12} sm='auto' shouldHide={shouldHideMap?.templateFlag}>
                    <CngCheckboxField
                      label={
                        <Typography style={{ fontSize: 12, lineHeight: 1.2 }}>
                          {translatedTextsObject.templateFlag}
                        </Typography>
                      }
                      name='templateFlag'
                      disabled={disabled}
                      onChange={(event) =>
                        setValue('templateFlag', event.target.checked)
                      }
                      size='small'
                      style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                      value={!templateFlag ? false : true}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm shouldHide={shouldHideMap?.templateName}>
                    <CngTextField
                      name='templateName'
                      label={translatedTextsObject.templateName}
                      disabled={!templateFlag}
                      size='small'
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CngSection
            title='Invoice information'
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>*</Typography>
                Mandatory fields
              </Typography>
            }
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.invoiceNumber}>
                <CngTextField
                  name='invoiceNumber'
                  label={translatedTextsObject.invoiceNumber}
                  disabled={disabled}
                  isRequired
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.invoiceDate}>
                <CngDateField
                  name='invoiceDate'
                  label={translatedTextsObject.invoiceDate}
                  disabled={disabled}
                  isRequired
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.carrierCode}>
                <CngTextField
                  name='carrierCode'
                  label={translatedTextsObject.carrierCode}
                  disabled={disabled}
                  isRequired={!getValues("carrierCode") && !getValues("carrierName")}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.carrierName}>
                <CngTextField
                  name='carrierName'
                  label={translatedTextsObject.carrierName}
                  disabled={disabled}
                  isRequired={!getValues("carrierCode")}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.purchaseOrderNum}>
                <CngTextField
                  name='purchaseOrderNum'
                  label={translatedTextsObject.purchaseOrderNum}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.tripVoyageNo}>
                <CngTextField
                  name='tripVoyageNo'
                  label={translatedTextsObject.tripVoyageNo}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.shipmentDate}>
                <CngDateField
                  name='shipmentDate'
                  label={translatedTextsObject.shipmentDate}
                  isRequired
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.ccn}>
                <CngTextField
                  name='ccn'
                  label={translatedTextsObject.ccn}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.currencey}>
                <CngCodeMasterAutocompleteField
                  name='currencey'
                  key={currencey}
                  label={translatedTextsObject.currencey}
                  disabled={disabled}
                  isRequired
                  size='small'
                  codeType='NBCI_INV_CUR'
                  lookupProps={{
                    label: (record) => `${record.code};${record.descriptionEn}`
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.portOfExit}>
                <NaAceHwyPOEPortAutocompleteField
                  size='small'
                  key={portOfExit}
                  name='portOfExit'
                  label={translatedTextsObject.portOfExit}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.transportMode}>
                <CngCodeMasterAutocompleteField
                  name='transportMode'
                  key={transportMode}
                  label={translatedTextsObject.transportMode}
                  isRequired
                  disabled={disabled}
                  size='small'
                  codeType='NBCI_CA_TRANSPORT'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.grossWeight}>
                <CngTextField
                  name='grossWeight'
                  label={translatedTextsObject.grossWeight}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.grossWeightUom}>
                <CngCodeMasterAutocompleteField
                  name='grossWeightUom'
                  key={grossWeightUom}
                  label={translatedTextsObject.grossWeightUom}
                  disabled={disabled}
                  size='small'
                  codeType='US_INV_WEIGHT_UOM'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.netWeight}>
                <CngTextField
                  name='netWeight'
                  label={translatedTextsObject.netWeight}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.netWeightUom}>
                <CngCodeMasterAutocompleteField
                  name='netWeightUom'
                  key={netWeightUom}
                  label={translatedTextsObject.netWeightUom}
                  disabled={disabled}
                  size='small'
                  codeType='US_INV_WEIGHT_UOM'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.entryNumber}>
                <CngTextField
                  name='entryNumber'
                  label={translatedTextsObject.entryNumber}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.netWeightUom}>
                <CngSelectField
                  name='transactionType'
                  label={translatedTextsObject.transactionType}
                  key={transactionType}
                  disabled={disabled}
                  size='small'
                  items={[
                    { text: 'CSA Receipt - Yes', value: 'Y' },
                    { text: 'CSA Receipt - No', value: 'N' }
                  ]}
                />
              </CngGridItem>
              <CngGridItem xs={12} shouldHide={shouldHideMap.marksNumbers}>
                <CngTextField
                  name='marksNumbers'
                  label={translatedTextsObject.marksNumbers}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CAInvoiceLookupsProvider>
            <TradePartySection />
          </CAInvoiceLookupsProvider>
        </Grid>
        <Grid item xs={12}>
          <CollapsibleSection
            defaultExpanded={!_.isEmpty(ciForm)}
            title='Additional Information'
          >
            <Box mb={2}>
              <Typography color='textSecondary'>
                {translatedTextsObject.ciForm}
              </Typography>
            </Box>
            <CiFormSection />
          </CollapsibleSection>
        </Grid>
        <Grid item xs={12}>
          <AdditionalFormInformationSection />
        </Grid>
      </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  localData.invDetails.forEach((invoiceDetail, index) => {
    invoiceDetail['_id'] = index
  })

  return localData
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties