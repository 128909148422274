import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!`^_:;.?()[]{}\'"'

  const regexDigit = '^[0-9]*$'
  const errMsgDigit = 'Invalid Value - Allowed Digits'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    additionDeductionInd: Yup.string().required(requiredMessage).nullable(),
    additionDeductionCode: Yup.string().required(requiredMessage).nullable(),
    amountRatePercent: validateStringField(10, regexDigit, errMsgDigit),
    additionDeductionType: Yup.string().required(requiredMessage).nullable(),
    explanation: validateStringField(22, regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
