import makeValidationSchema from './BolFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import NBCICanadaInvoiceKeys from 'src/constants/locale/key/NBCICanadaInvoice'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField, CngSelectField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  containerNo: '',
  freightCharges: '',
  placeOfLadding: '',
  deliveryInstructions: '',
  codAmount: '',
  currency: '',
  declareValueOfShipment: '',
  chargeTo: '',
  releaseValue: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const containerNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.CONTAINER_NO
    )
    const freightCharges = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.FREIGHT_CHARGES
    )
    const placeOfLadding = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.PLACE_OF_LADDING
    )
    const deliveryInstructions = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.DELIVERY_INSTRUCTIONS
    )
    const codAmount = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.COD_AMOUNT
    )
    const currency = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.CURRENCY
    )
    const declareValueOfShipment = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.DECLARE_VALUE_OF_SHIPMENT
    )
    const chargeTo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.CHARGE_TO
    )
    const releaseValue = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.RELEASE_VALUE
    )

    return {
      containerNo,
      codAmount,
      freightCharges,
      declareValueOfShipment,
      placeOfLadding,
      releaseValue,
      currency,
      deliveryInstructions,
      chargeTo,
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.containerNo}>
        <CngTextField
          name='containerNo'
          label={translatedTextsObject.containerNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.codAmount}>
        <CngTextField
          name='codAmount'
          label={translatedTextsObject.codAmount}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.freightCharges}>
        <CngSelectField
          name='freightCharges'
          label={translatedTextsObject.freightCharges}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Prepaid', value: 'Y' },
            { text: 'Collect', value: 'N' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.declareValueOfShipment}>
        <CngTextField
          name='declareValueOfShipment'
          label={translatedTextsObject.declareValueOfShipment}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.placeOfLadding}>
        <CngTextField
          name='placeOfLadding'
          label={translatedTextsObject.placeOfLadding}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.releaseValue}>
        <CngTextField
          name='releaseValue'
          label={translatedTextsObject.releaseValue}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.currency}>
        <CngSelectField
          name='currency'
          label={translatedTextsObject.currency}
          disabled={disabled}
          size='small'
          items={[
            { text: 'USD', value: 'USD' },
            { text: 'CAD', value: 'CAD' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.deliveryInstructions}>
        <CngTextField
          name='deliveryInstructions'
          label={translatedTextsObject.deliveryInstructions}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.chargeTo}>
        <CngTextField
          name='chargeTo'
          label={translatedTextsObject.chargeTo}
          disabled={disabled}
          size='small'
          helperText='If other than Shipper or Consignee'
        />
      </CngGridItem>
    </Grid>
  )
}

const BolFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default BolFormFormProperties
