import React, { useEffect, useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import UsProductMasterKeys from '../../../constants/locale/key/UsProductMaster'
import makeValidationSchema from './MakeValidationSchema'
import { NaHSCodeAutocompleteField } from '../../../components/na'
import CngSection from '../../../components/cngcomponents/CngSection'
import RadioButtonGroup from '../../../components/aciacehighway/RadioButtonGroup'
import Alert from '../../../components/Alert'
import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { NaNbciCountryCodeAutocompleteField } from 'src/components/na/autocomplete/codemaintenance'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCheckboxField,
      CngUomAutocompleteField,
      CngCountryAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  table: { useFetchCodeMaintenanceLookup },
  CngGridItem,
} = components

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  usorcaMaster: "US",
  productId: "",
  manufacturingPartNo: "",
  itemDescription: "",
  productClassificationCode: "",
  countryOfOrigin: "",
  uom: "",
  unitPrice: "",
  tariffNo: "",
  markPackLoading: "",
  includeProduct: "N",
  isProducer: "Y",
  notAProducerOfGoods: "",
  naftaCriteria: "",
  netCost: "",
  dateRangeFrom: "",
  dateRangeTo: "",
  manufacturingPartyName: "",
  manufacturingMID: "",
  manufacturingFDARegNum: "",
  growerPartyName: "",
  growerMID: "",
  growerFDARegNumber: "",
  consolidatorPartyName: "",
  consolidatorMID: "",
  consolidatorFDARegNumber: "",
  hsCode: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const [productMasterTypes, setProductMasterTypes] = useState([])
  const { setValue, watch } = useFormContext()

  const usorcaMaster = watch('usorcaMaster')
  const countryOfOrigin = watch('countryOfOrigin')
  const includeProduct = watch('includeProduct')
  const isProducer = watch('isProducer')
  const notAProducerOfGoods = watch('notAProducerOfGoods')

  const { translate } = useTranslation(Namespace.US_PRODUCT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    async function fetchLookups() {
      try {
        const productMasterTypes = await fetchCodeMaintenanceLookup(
          CodeMaintenanceType.CODE_MASTER,
          undefined,
          [{ field: 'codeType', operator: EQUAL, value: 'US_OR_CA_MASTER' }]
        )
        
        const result = []

        for (const [key, value] of Object.entries(productMasterTypes)) {
          result.push({ label: value, value: key })
        }
  
        setProductMasterTypes(result)
        setValue('usorcaMaster', usorcaMaster)
      } catch (error) {
        console.error(error)
      }
    }

    fetchLookups()
  }, [])

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    const usProductMaster = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.TITLE
    )
    const productId = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.PRODUCT_ID
    )
    const manufacturingPartNo = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MANUFACTURING_PART_NO
    )
    const itemDescription = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.ITEM_DESCRIPTION
    )
    const productClassificationCode = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.PRODUCT_CLASSIFICATION_CODE
    )
    const hsCode = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.HS_CODE
    )
    const countryOfOrigin = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.COUNTRY_OF_ORIGIN
    )
    const uom = translate(Namespace.US_PRODUCT_MASTER, UsProductMasterKeys.UOM)
    const unitPrice = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.UNIT_PRICE
    )
    const tariffNo = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.TARIFF_NO
    )
    const markPackLoading = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MARK_PACK_LOADING
    )
    const usmcaLabel = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.USMCA_LABEL
    )
    const includeProduct = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.INCLUDE_PRODUCT
    )
    const isProducer = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.IS_PRODUCER
    )
    const notAProducerOfGoods = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.NOT_A_PRODUCER_OF_GOODS
    )
    const exporterLabelOne = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.EXPORTER_LABEL_ONE
    )
    const exporterLabelTwo = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.EXPORTER_LABEL_TWO
    )
    const exporterLabelThree = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.EXPORTER_LABEL_THREE
    )
    const naftaCriteria = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.NAFTA_CRITERIA
    )
    const netCost = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.NET_COST
    )
    const dateRangeFrom = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.DATE_RANGE_FROM
    )
    const dateRangeTo = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.DATE_RANGE_TO
    )
    const manufacturingLabel = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MANUFACTURING_LABEL
    )
    const manufacturingPartyName = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MANUFACTURING_PARTY_NAME
    )
    const manufacturingMID = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MANUFACTURING_M_I_D
    )
    const manufacturingFDARegNum = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MANUFACTURING_F_D_A_REG_NUM
    )
    const growerLabel = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.GROWER_LABEL
    )
    const growerPartyName = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.GROWER_PARTY_NAME
    )
    const growerMID = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.GROWER_M_I_D
    )
    const growerFDARegNumber = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.GROWER_F_D_A_REG_NUMBER
    )
    const consolidatorLabel = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.CONSOLIDATOR_LABEL
    )
    const consolidatorPartyName = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.CONSOLIDATOR_PARTY_NAME
    )
    const consolidatorMID = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.CONSOLIDATOR_M_I_D
    )
    const consolidatorFDARegNumber = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.CONSOLIDATOR_F_D_A_REG_NUMBER
    )
    const note = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.NOTE
    )
    const noteLabel = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.NOTE_LABEL
    )

    return {
      usProductMaster,
      productId,
      manufacturingPartNo,
      itemDescription,
      hsCode,
      productClassificationCode,
      countryOfOrigin,
      uom,
      unitPrice,
      tariffNo,
      markPackLoading,
      usmcaLabel,
      includeProduct,
      isProducer,
      naftaCriteria,
      netCost,
      dateRangeFrom,
      dateRangeTo,
      notAProducerOfGoods,
      exporterLabelOne,
      exporterLabelTwo,
      exporterLabelThree,
      manufacturingLabel,
      manufacturingPartyName,
      manufacturingMID,
      manufacturingFDARegNum,
      growerLabel,
      growerPartyName,
      growerMID,
      growerFDARegNumber,
      consolidatorLabel,
      consolidatorPartyName,
      consolidatorMID,
      consolidatorFDARegNumber,
      noteLabel,
      note
    }
  }

  function handleChangeCountryOfOrigin() {
    setValue('includeProduct', 'N')
    setValue('isProducer', 'Y')
    setValue('notAProducerOfGoods', '')
    setValue('naftaCriteria', '')
    setValue('netCost', '')
    setValue('dateRangeFrom', '')
    setValue('dateRangeTo', '')
  }

  function handleChangeIncludeProduct(event) {
    setValue('includeProduct', event.target.checked ? 'Y' : 'N')
    
    if (!event.target.checked) {
      setValue('naftaCriteria', '')
      setValue('dateRangeFrom', '')
      setValue('dateRangeTo', '')
      setValue('isProducer', 'N')
      setValue('notAProducerOfGoods', '')
    }
  }

  function handleChangeIsProducer(event) {
    setValue('isProducer', event.target.checked ? 'Y' : 'N')
    
    if (event.target.checked) {
      setValue('notAProducerOfGoods', '')
    }
  }

	return (
    <CngSection title={translatedTextsObject.usProductMaster}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <RadioButtonGroup
            onChange={(event) => setValue('usorcaMaster', event.target.value)}
            options={productMasterTypes}
            value={usorcaMaster}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.productId}>
              <CngTextField
                name='productId'
                inputProps={{ maxLength: 50 }}
                onChange={(e) => setValue('productId', e.target.value.toUpperCase())}
                label={translatedTextsObject.productId}
                isRequired
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.manufacturingPartNo}>
              <CngTextField
                name='manufacturingPartNo'
                inputProps={{ maxLength: 25 }}
                onChange={(e) => setValue('manufacturingPartNo', e.target.value.toUpperCase())}
                label={translatedTextsObject.manufacturingPartNo}
                isRequired
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={6} shouldHide={shouldHideMap.itemDescription}>
              <CngTextField
                name='itemDescription'
                inputProps={{ maxLength: 80 }}
                onChange={(e) => setValue('itemDescription', e.target.value.toUpperCase())}
                label={translatedTextsObject.itemDescription}
                isRequired
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <Grid item xs={12} sm={6} md={3}>
              {usorcaMaster === 'CA' ? (
                <CngTextField
                  name='hsCode'
                  label={translatedTextsObject.hsCode}
                  disabled={disabled}
                  size='small'
                />
              ) : (
                <CngTextField
                  name='productClassificationCode'
                  inputProps={{ maxLength: 8 }}
                  onChange={(e) => setValue('productClassificationCode', e.target.value.toUpperCase())}
                  label={translatedTextsObject.productClassificationCode}
                  disabled={disabled}
                  size='small'
                />
              )}
            </Grid>
            {usorcaMaster === 'US' && (
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.countryOfOrigin}>
              <CngCountryAutocompleteField
                name='countryOfOrigin'
                inputProps={{ maxLength: 25 }}
                onChange={handleChangeCountryOfOrigin}
                label={translatedTextsObject.countryOfOrigin}
                isRequired
                disabled={disabled}
                size='small'
              />
            </CngGridItem>)}
            {usorcaMaster === 'CA' && (
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.countryOfOrigin}>
              <NaNbciCountryCodeAutocompleteField
                name='countryOfOrigin'
                inputProps={{ maxLength: 25 }}
                onChange={handleChangeCountryOfOrigin}
                label={translatedTextsObject.countryOfOrigin}
                isRequired
                disabled={disabled}
                size='small'
              />
            </CngGridItem>)}
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.uom}>
              <CngUomAutocompleteField
                name='uom'
                lookupProps={{
                  filters: [{ field: 'uomType', operator: EQUAL, value: 'UPS' }]
                }}
                label={translatedTextsObject.uom}
                isRequired
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.unitPrice}>
              <CngTextField
                name='unitPrice'
                inputProps={{ maxLength: 19 }}
                label={translatedTextsObject.unitPrice}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            {usorcaMaster !== 'CA' && (
              <CngGridItem xs={12} sm={6} md={3}>
                <NaHSCodeAutocompleteField
                  name='tariffNo'
                  inputProps={{ maxLength: 10 }}
                  label={translatedTextsObject.tariffNo}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            )}
          </Grid>
        </Grid>
        {(countryOfOrigin === 'US' || countryOfOrigin === 'CA') && (
          <Grid item xs={12}>
            <CngSection
              title={translatedTextsObject.usmcaLabel}
              action={
                <Card variant='outlined'>
                  <Box padding={1}>
                    <Grid alignItems='center' container spacing={2}>
                      <CngGridItem xs={12} sm={6}>
                        <FormControlLabel
                          control={<Checkbox checked={includeProduct === 'Y'} />}
                          label={
                            <Typography
                              color='textSecondary'
                              variant='body2'
                              style={{ fontWeight: 600 }}
                            >
                              {translatedTextsObject.includeProduct}
                            </Typography>
                          }
                          onChange={handleChangeIncludeProduct}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.naftaCriteria}>
                        <CngCodeMasterAutocompleteField
                          name='naftaCriteria'
                          label={translatedTextsObject.naftaCriteria}
                          codeType='ORIGIN_CRITERION'
                          disabled={includeProduct !== 'Y'}
                          size='small'
                        />
                      </CngGridItem>
                    </Grid>
                  </Box>
                </Card>
              }
            >
              <Grid container spacing={1}>
                {includeProduct === 'Y' && (
                  <>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.dateRangeFrom}>
                      <CngDateField
                        name='dateRangeFrom'
                        label={translatedTextsObject.dateRangeFrom}
                        disabled={disabled}
                        format='DD/MM/YYYY'
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.dateRangeTo}>
                      <CngDateField
                        name='dateRangeTo'
                        label={translatedTextsObject.dateRangeTo}
                        disabled={disabled}
                        format='DD/MM/YYYY'
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.netCost}>
                      <CngCodeMasterAutocompleteField
                        name='netCost'
                        label={translatedTextsObject.netCost}
                        codeType='NBCI_PROD_NET_COST'
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                  </>
                )}
                <CngGridItem xs={12} shouldHide={shouldHideMap.notAProducerOfGoods}>
                  <Card variant='outlined'>
                    <Box padding={2}>
                      <Grid container spacing={2}>
                        <CngGridItem xs={12} shouldHide={shouldHideMap.isProducer}>
                          <CngCheckboxField
                            checked={isProducer === 'Y'}
                            label={translatedTextsObject.isProducer}
                            name='isProducer'
                            onChange={handleChangeIsProducer}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} shouldHide={shouldHideMap.notAProducerOfGoods}>
                          <RadioButtonGroup
                            disabled={isProducer === 'Y'}
                            itemsPerRow={3}
                            onChange={(event) => setValue('notAProducerOfGoods', event.target.value)}
                            options={[
                              {
                                label: translatedTextsObject.exporterLabelOne,
                                value: '1'
                              },
                              {
                                label: translatedTextsObject.exporterLabelTwo,
                                value: '2'
                              },
                              {
                                label: translatedTextsObject.exporterLabelThree,
                                value: '3'
                              }
                            ]}
                            value={notAProducerOfGoods}
                          />
                        </CngGridItem>
                      </Grid>
                    </Box>
                  </Card>
                </CngGridItem>
              </Grid>
            </CngSection>
          </Grid>
        )}
        {usorcaMaster !== 'CA' && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  Weight Information
                </Typography>
              </Grid>
              <CngGridItem xs={12} shouldHide={shouldHideMap.markPackLoading}>
                <CngTextField
                  name='markPackLoading'
                  inputProps={{ maxLength: 80 }}
                  onChange={(e) => setValue('markPackLoading', e.target.value.toUpperCase())}
                  label={translatedTextsObject.markPackLoading}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </Grid>
        )}
        {usorcaMaster === 'US' && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  Party Information
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Card variant='outlined'>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box padding={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography style={{ fontWeight: 600 }}>
                              {translatedTextsObject.manufacturingLabel}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.manufacturingPartyName}>
                                <CngTextField
                                  name='manufacturingPartyName'
                                  inputProps={{ maxLength: 60 }}
                                  onChange={(e) => setValue('manufacturingPartyName', e.target.value.toUpperCase())}
                                  label={translatedTextsObject.manufacturingPartyName}
                                  disabled={disabled}
                                  size='small'
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.manufacturingMID}>
                                <CngTextField
                                  name='manufacturingMID'
                                  inputProps={{ maxLength: 20 }}
                                  onChange={(e) => setValue('manufacturingMID', e.target.value.toUpperCase())}
                                  label={translatedTextsObject.manufacturingMID}
                                  disabled={disabled}
                                  size='small'
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.manufacturingFDARegNum}>
                                <CngTextField
                                  name='manufacturingFDARegNum'
                                  inputProps={{ maxLength: 60 }}
                                  onChange={(e) => setValue('manufacturingFDARegNum', e.target.value.toUpperCase())}
                                  label={translatedTextsObject.manufacturingFDARegNum}
                                  disabled={disabled}
                                  size='small'
                                />
                              </CngGridItem>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Box padding={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography style={{ fontWeight: 600 }}>
                              {translatedTextsObject.growerLabel}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.growerPartyName}>
                                <CngTextField
                                  name='growerPartyName'
                                  inputProps={{ maxLength: 60 }}
                                  onChange={(e) => setValue('growerPartyName', e.target.value.toUpperCase())}
                                  label={translatedTextsObject.growerPartyName}
                                  disabled={disabled}
                                  size='small'
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.growerMID}>
                                <CngTextField
                                  name='growerMID'
                                  inputProps={{ maxLength: 20 }}
                                  onChange={(e) => setValue('growerMID', e.target.value.toUpperCase())}
                                  label={translatedTextsObject.growerMID}
                                  disabled={disabled}
                                  size='small'
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.growerFDARegNumber}>
                                <CngTextField
                                  name='growerFDARegNumber'
                                  inputProps={{ maxLength: 60 }}
                                  onChange={(e) => setValue('growerFDARegNumber', e.target.value.toUpperCase())}
                                  label={translatedTextsObject.growerFDARegNumber}
                                  disabled={disabled}
                                  size='small'
                                />
                              </CngGridItem>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Box padding={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography style={{ fontWeight: 600 }}>
                              {translatedTextsObject.consolidatorLabel}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.consolidatorPartyName}>
                                <CngTextField
                                  name='consolidatorPartyName'
                                  inputProps={{ maxLength: 60 }}
                                  onChange={(e) => setValue('consolidatorPartyName', e.target.value.toUpperCase())}
                                  label={translatedTextsObject.consolidatorPartyName}
                                  disabled={disabled}
                                  size='small'
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.consolidatorMID}>
                                <CngTextField
                                  name='consolidatorMID'
                                  inputProps={{ maxLength: 20 }}
                                  onChange={(e) => setValue('consolidatorMID', e.target.value.toUpperCase())}
                                  label={translatedTextsObject.consolidatorMID}
                                  disabled={disabled}
                                  size='small'
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.consolidatorFDARegNumber}>
                                <CngTextField
                                  name='consolidatorFDARegNumber'
                                  inputProps={{ maxLength: 60 }}
                                  onChange={(e) => setValue('consolidatorFDARegNumber', e.target.value.toUpperCase())}
                                  label={translatedTextsObject.consolidatorFDARegNumber}
                                  disabled={disabled}
                                  size='small'
                                />
                              </CngGridItem>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Alert
                  severity='info'
                  icon={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
                >
                  <strong>{translatedTextsObject.noteLabel}:</strong>
                  &nbsp;{translatedTextsObject.note}
                </Alert>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </CngSection>
	)
}

function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
